export default {
  // Merchant and sellers list
  stockStatus: {
    all: "All offers"
  },
  cpaStatus: {
    placeholder: "Type of advertisement",
    nocpa: "Without advertising",
    balanceIsOver: "Balance is over"
  },
  searchTags: {
    ga_label: "Custom label, Google",
    fb_label: "Custom label, Facebook",
    cs_code: "CS-code",
    title: "Product name",
    seller_slug: "Seller",
    category_path: "Category"
  },
  facebookChangePriceList: {
    none: "Don't change",
    add: "Add selected",
    remove: "Remove selected"
  },
  facebookPriceList: "Fb price list",
  changeGoogleLabelMessage:
    "Changes to Google's custom label of selected offers will not affect Facebook's custom label in any way",
  facebookPriceListOptions: {
    none: "Among the selected offers, none is added or removed from the Fb price list. If they were not, they will not appear in the price list, but will receive a new custom label",
    add: "All selected offers will be added to the price list of Fb Ukrainian and Russian",
    remove: "All selected offers will be removed to the price list of Fb Ukrainian and Russian"
  },
  facebookPriceListError:
    "The selected offers do not have a custom label Fb, so it is impossible to add them to the price list. To get started, specify the custom label",
  chooseOrDragForUpload: "Click or drag a file to this area to upload file",
  massiveFileChangeError: "An error occurred during a mass change",
  additionalButtons: {
    addToBlackList: "Add to BL",
    selectCl: "Select CL"
  },
  popOver: {
    accountHealth: "Account health",
    orderCount: "Orders count",
    orderSuccess: "Successfully closed by count",
    orderSuccessSum: "Successfully closed by money",
    tariffExpire: "Subscription end date",
    dateAdded: "Date added to advertisement"
  },
  table: {
    price: "Price",
    inStockShort: "In st.",
    clicks: {
      short: "Cl.",
      full: "Clicks"
    },
    middlePrice: {
      short: "A.p.",
      full: "Avarage price of click"
    },
    cost: {
      short: "Ad.p",
      full: "Advertisment price"
    },
    conversion: {
      short: "C.p.",
      full: "Conversion value"
    },
    soldCount: {
      short: "Sld.",
      full: "Solded count"
    },
    soldPrice: {
      short: "Sld. uah",
      full: "Solded uah"
    },
    offers: {
      short: "Of.",
      full: "Offers"
    },
    orders: {
      short: "Ord.",
      full: "Orders"
    },
    shows: {
      short: "Sh.",
      full: "Showings"
    },
    lostShows: {
      short: "L.s.",
      full: "of losted shows (rating)"
    },
    dateExpires: {
      short: "Subs. to",
      full: "Subscribed to"
    },
    dateAdded: {
      short: "Added",
      full: "Date of adding to advertisment"
    },
    leftOver: {
      short: "Lefto.",
      full: "Left Over"
    },
    allOfOrders: {
      title: "All of orders",
      percent: "% of successfull orders"
    }
  },
  states: {
    all: "All states"
  },
  defaultCLabel: "Default C-label",
  shopBalance: "Shop balance",
  on_hold: "On hold",
  leftover: "Avaliable",
  applied_total: "Applied",
  pending_total: "On pending",
  canceled_total: "Cancelled",
  balanceHistory: "Balance history",
  creatingReport: "Creating a report",
  writtenOff: "Written off",
  balanceCreatedAt: "Date changes",
  balanceBefore: "Balance before",
  balanceAfter: "Balance after",
  billingCode: "Billing-code",
  billingCodeSeparatedByComma: "List of billing codes separated by comma, space or Enter",
  billingCodeError: "Billing-codes are incorrect",
  createReport: "To form",
  addToBalance: "Add to balance",
  takeFromBalance: "Subtract from balance",
  dateWriteOff: "Write off date",
  dateOrdered: "Ordered date",
  feedLogic: "All offers of this seller are included in the merchandise feed (not standard logic)",
  feedLogicFacebook: "All offers of this seller are included in the FB feed (not standard logic)",
  feedLogicEkatalog: "All offers of this seller are included in the EK feed (not standard logic)",
  feedConditionLogicLabel: "Transform discount to new (GA)",
  standartLogic: "Standart logic",
  allOffers: "All offers",
  feedLogicPlaceholder: "Fall into feed",
  massiveCLChange: "Massive c-label change",
  chooseForMassiveChange: "Choose for massive change",
  fileFormatForMassiveChange: "File format for custom label change",
  massiveFileCLChange: "Massive c-label change (file)",
  massiveCLInput: "Massive C-label input",
  massiveChangeWithFileStarted: "A mass change (file) has started",
  massiveChangeWithFileSuccess: "Massive change (file) was successfull",
  customLabelChange: "Change custom label",
  customLabelChangeGoogle: "Change custom label Google",
  customLabelChangeFacebook: "Change custom label Facebook",
  activityChangeFacebook: "Change Facebook activity",
  activityChangeEkatalog: "Change E-katalog activity",
  facebook_marks: "Fb activity",
  ekatalog_marks: "Ek activity",

  merchantFileChangeAlerts: {
    ekatalog_marks: ["Chosen offers will be added or removed from Ek price list"],
    facebook_marks: ["Chosen offers will be added or removed from Fb price list"],
    ga_default_label: [
      "Custom label will be changed in chosen offers",
      "",
      "The recommended number of offers in single file is no more than 50 thousands"
    ],
    fb_default_label: [
      "Among the selected offers, none is added or removed from the Fb price list",
      "If they were in the Fb price list, they remain and receive a new custom label",
      "If they were not, they will not appear in the price list, but will receive a new custom label",
      "",
      "The recommended number of offers in single file is no more than 50 thousands"
    ]
  },
  transactionType: "Transaction type",

  transactionTypes: {
    replenishment: "Replenishment by supervisor",
    bonusesReplenishment: "Replenishment from bonuses by supervisor",
    writeOff: "Writing off the commission by supervisor",
    writeOffBalance: "Debiting funds by supervisor"
  },

  balanceChanges: "Balance changes",
  // order Statuses
  orderStatuses: {
    new: "New",
    inProgress: "In progress",
    approved: "Approved by seller",
    shipped: "Shipped",
    closed: "Successfully closed",
    seller_approved: "Approved",
    canceled_by_customer: "Canceled by customer",
    canceled_by_seller: "Canceled by seller",
    canceled_by_supervisor: "Canceled by administration",
    canceledBy: {
      seller: "Canceled by seller",
      customer: "Canceled by customer",
      supervisor: "Canceled by supervisor"
    }
  },
  clearTags: "Clear tags",
  sellers: "Sellers",
  sellerList: "List of sellers",
  sellerState: "Seller state",
  // Black list
  blackList: "Black list",
  addedToBL: "Added to black list",
  removeFromBL: "Remove from black list",
  removedFromBL: "Removed from black list",
  toBlackList: "to black list",
  // Ref
  sellerPlusSlug: "Seller (slug)",
  categories: "Categories",
  brands: "Brands",
  commission: "Commission",
  commissionShorted: "Commis.",
  commissionStatus: "Commission status",
  commissionStatusShorted: "Com. status",
  reward: "Reward",
  rewardShorted: "Rew.",
  rewardStatusShorted: "Rew. status",
  payments: "Payments",
  advertismentType: "Advertisment type",
  refuseReason: "Refuse reason",
  billing: "Billing",
  changeCommissionStatus: "Change commission status",
  reasonCommissionDecline: "Reason of decline the commission",
  dateOrderUpdate: "Order update date",
  dateOfferUpdate: "Offer update date",
  referrer: "Referrer",
  referrers: "Referrers",
  reasonDeclineOrder: "Reason of decline order",
  reasonDeclineOffer: "Reason of decline offer",
  refCount: "Ref count",
  refActiveCount: "Ref active count",
  positionsToDebit: "Debit positions",
  sumToDebit: "Debit amount",
  // CPC
  createCampaign: "Creating a campaign",
  creatingCampaign: "Campaign creating",
  editCampaign: "Edit campaign",
  editCampaignCL: "Edit Custom label of campaign",
  campaignStatus: "Campaign status",
  consumptionYesterday: "Yesterday's expenses",
  inc25: "Rising costs >25%",
  inc50: "Rising costs >50%",
  inc100: "Rising costs >100%",
  dec25: "Cost reductions >25%",
  dec50: "Cost reductions >50%",
  dec100: "Cost reductions >100%",
  negativeBalance: "Negative balance",
  lowBalance: "Running out of budget tomorrow",
  searchBySellerSlug: "Search by seller slug",
  debitHistory: "Debit history",
  clearCustomLabel: "Clear custom label for seller",
  dateOrder: "Date order",
  codeOrder: "Code order",
  statusOrder: "Order status"
}
