import { jwtDecode } from "jwt-decode"
import { API, API_V2 } from "../../utils/HttpUtils"
import Cookies from "js-cookie"
import router from "@/router"
import { pathForComponents } from "@/components/Layout/Navigation/navigationComponents"
import notifyResponseError from "@/utils/notifyResponseError"

const auth = {
  namespaced: true,

  state: {
    profile: {},
    permissions: [],
    isLogged: false,
    loginRequest: false,
    logoutRequest: false,
    profileRequest: false,
    permissionsRequest: false
  },

  mutations: {
    loginRequest(state) {
      state.loginRequest = true
    },

    loginSuccess(state, payload) {
      state.loginRequest = false
      state.isLogged = true
      state.profile = payload
    },

    loginFailure(state) {
      state.loginRequest = false
      state.isLogged = false
    },

    logoutRequest(state) {
      state.logoutRequest = true
    },

    logoutSuccess(state) {
      state.logoutRequest = false
      state.profile = {}
      state.isLogged = false
    },

    setLogout(state) {
      state.logoutRequest = false
      state.profile = {}
      state.isLogged = false
    },

    logoutFailure(state) {
      state.logoutRequest = false
    },

    getProfileRequest(state) {
      state.profileRequest = true
    },

    getProfileSuccess(state, payload) {
      state.profileRequest = false
      state.profile = payload
      state.isLogged = true
    },

    getProfileFailure(state) {
      state.profileRequest = false
      state.isLogged = false
    },

    getPermissionsRequest(state) {
      state.permissionsRequest = true
    },

    getPermissionsSuccess(state, permissions) {
      state.permissionsRequest = false
      state.permissions = permissions
    },

    getPermissionsFailure(state) {
      state.permissionsRequest = false
      state.permissions = []
    }
  },

  actions: {
    async login({ commit, dispatch }, authParams) {
      commit("loginRequest")

      try {
        const { data } = await API_V2.post("admin/auth/login/", {
          login: authParams.username,
          password: authParams.password
        })

        await dispatch("setTokens", data)

        API_V2.defaults.headers.common["Authorization"] = "Bearer " + data.access_token
      } catch (e) {
        console.log(e)
      }

      try {
        const response = await API.post("/auth/login", authParams)

        const permissions = await dispatch("getUserPermissions")
        if (router.currentRoute.query.next && router.currentRoute.query.next !== "/") {
          router.push({ path: router.currentRoute.query.next })
        } else {
          const firstCode = permissions.find((code) => pathForComponents[code])

          if (firstCode) {
            router.push({ path: pathForComponents[firstCode] })
          } else {
            router.push({ path: "/mp-admin/categories/" })
          }
        }
        commit("loginSuccess", response.data)
      } catch {
        commit("loginFailure")
      }
    },

    async logout({ commit, dispatch }) {
      commit("logoutRequest")

      try {
        await API_V2.post("admin/auth/logout/")
        API_V2.defaults.headers.common.Authorization = undefined
      } catch (e) {
        console.log(e)
      }

      try {
        const response = await API.post("/auth/logout")
        dispatch("clearTokens")
        commit("logoutSuccess", response.data)

        if (router.currentRoute.path !== "/login") {
          router.push("/login")
        }
      } catch (error) {
        commit("logoutFailure", error)
        notifyResponseError({ error })
      }
    },

    setLogout({ commit }) {
      commit("setLogout")
    },

    async getProfile({ commit, dispatch }) {
      commit("getProfileRequest")

      let access = Cookies.get("access_token_mpa")
      if (!access) {
        try {
          const { access_token } = await dispatch("sendRefreshToken")
          access = access_token
        } catch {
          if (Cookies.get("sessionId")) {
            dispatch("logout")
          }

          if (router.currentRoute.path !== "/login") {
            router.push("/login")
          }

          return commit("getProfileFailure")
        }
      }

      API_V2.defaults.headers.common["Authorization"] = "Bearer " + access

      try {
        const { data } = await API.get("/users-management/users/profile")

        commit("getProfileSuccess", data)
        dispatch("getUserPermissions")
      } catch {
        if (router.currentRoute.path !== "/login") {
          router.push("/login")
        }
        commit("getProfileFailure")
      }
    },

    async sendRefreshToken({ dispatch }) {
      return new Promise(async (res, rej) => {
        try {
          const refresh = Cookies.get("refresh_token_mpa")
          if (!refresh) return rej("Not authorized")

          const { data } = await API_V2.post(
            "admin/auth/refresh/",
            {},
            {
              headers: {
                Authorization: "Bearer " + refresh
              }
            }
          )

          await dispatch("setTokens", data)
          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    setTokens({}, data) {
      return new Promise((res, rej) => {
        try {
          const { access_token, refresh_token } = data

          if (!access_token) {
            rej(new Error("Access token is invalid"))
            return
          }

          Cookies.set("access_token_mpa", access_token, { expires: 1 / 24 })
          Cookies.set("refresh_token_mpa", refresh_token, { expires: 8 / 24 })

          res(data)
        } catch (e) {
          rej(e)
        }
      })
    },

    clearTokens({}) {
      Cookies.remove("access_token_mpa")
      Cookies.remove("refresh_token_mpa")
    },

    getUserPermissions({ commit }) {
      commit("getPermissionsRequest")

      const accessToken = Cookies.get("access_token_mpa")
      // До моменту повного переходу на нове API немає сенсу робити запит на оновлення токену
      // TODO: Після переходу на нове API, додати логіку оновлення токену
      if (!accessToken) {
        commit("getPermissionsFailure")
        return router.push("/login")
      }

      const { permissions } = jwtDecode(accessToken)
      if (!permissions) {
        commit("getPermissionsFailure")
        return router.push("/login")
      }

      commit("getPermissionsSuccess", permissions)
      return permissions
    }
  },

  getters: {
    permissions: (state) => {
      return state.permissions
    }
  },

  modules: {}
}

export default auth
