<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "Tags",

  methods: {
    ...mapActions("Auth", ["logout"])
  },

  provide() {
    return {
      logout: this.logout
    }
  }
}
</script>
