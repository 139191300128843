export default () => {
  const getCodes = (code) => code.split("/")

  const checkRouteUrl = ({ code, permissions }) => {
    const codes = getCodes(code)
    const application = permissions.find((item) => item === codes[0])

    return Boolean(application)
  }

  const checkApplicationPermission = ({ code, permissions }) => {
    const codes = getCodes(code)
    const application = permissions.find((item) => item === codes[0])

    return Boolean(application)
  }

  const checkComponentPermission = ({ code, permissions }) => {
    const codes = getCodes(code)

    const component = permissions.find((item) => item === codes[1])
    return Boolean(component)
  }

  return {
    checkRouteUrl,
    checkApplicationPermission,
    checkComponentPermission
  }
}
