import { MESSENGER_URL_XHR } from "@/constants/common"
import { API_V2 } from "@/utils/HttpUtils"

export const getModerationToken = () => {
  return API_V2.get("/proxy/msg/support-auth")
}

export const getUnreadMessagesCount = (access) => {
  return fetch(`${MESSENGER_URL_XHR}/api/v2/chats/messages/unread-count/?sender=moderator`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`
    }
  })
}