const UsersManagement = () => import("./UsersManagement") //lazy load

import Users from "./pages/Users/Users"
import Roles from "./pages/Roles/Roles"
import i18n from "@/i18n"

export const UserManagementRoutes = [
  {
    path: "users",
    fullPath: "/users-management/users",
    name: 'Users',
    meta: {
      permissionType: "component",
      permissionCode: "UMGMT/USRCMP",
      code: "USRCMP",
      layout: "ant-layout",
      // title: i18n.t("users")
    }
  },
  {
    path: "roles",
    fullPath: "/users-management/roles",
    name: 'Roles',
    meta: {
      permissionType: "component",
      permissionCode: "UMGMT/RLSCMP",
      code: "RLSCMP",
      layout: "ant-layout",
      // title: i18n.t("roles")
    }
  }
]

const components = {
  users: Users,
  roles: Roles
}

export default {
  path: "/users-management",
  component: UsersManagement,
  meta: {
    permissionType: "application",
    permissionCode: "UMGMT",
    code: "UMGMT"
  },
  children: UserManagementRoutes.map(route => ({...route, component: components[route.path]}))
}
